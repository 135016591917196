export function computeFrequencyMap<T>(values: T[], accessor: (value: T) => string): Map<string, number> {
  const aggregationMap = new Map();
  values.forEach((value) => {
    const key = accessor(value);
    aggregationMap.set(key, (aggregationMap.get(key) || 0) + 1);
  });
  return aggregationMap;
}

export class DefaultMap<K = string, V = unknown> extends Map<K, V> {
  get(key: K) {
    let v = super.get(key); // using get instead of has saves the extra lookup..
    if (v === undefined) {
      v = this.defaultValueGenerator();
      this.set(key, v);
    }
    return v;
  }

  constructor(public defaultValueGenerator: () => V) {
    super();
  }
}
