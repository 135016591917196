import { useEffect, useState } from "react";
import "../App.css";
import { InputLabel, Box, Button, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import { NavBar } from "../NavBarMenu";
import { SearchableDropdown } from "../SearchableDropdown";
import { FailureReasons } from "../plots/FailureReasons";
import { PipelineStatusOverview } from "../plots/PipelineStatus";
import { SuccessRateOverTime } from "../plots/SuccessRate";
import { PipelineInformation } from "../types";
import { SpacedDiv } from "../styles";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { JobsOverTime } from "../plots/JobStatistics";
import { fetchData } from "../DataProcessing";

export function Overview() {
  const allSelection = "all";
  const [selectedProject, setSelectedProject] = useState<string>(allSelection);
  const [knownProjects, setKnownProjects] = useState<string[]>([]);
  const [selectedJobName, setSelectedJobName] = useState<string>(allSelection);
  const [knownJobNames, setKnownJobNames] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [plottingData, setPlottingData] = useState<PipelineInformation[]>([]);
  const [timeResolution, setTimeResolution] = useState(1); //in days

  useEffect(() => {
    fetch("/ci_cd/get_project_names")
      .then((response) => response.json())
      .then((data: any[]) => {
        setKnownProjects([allSelection, ...data.map((project: { project_name: string }) => project.project_name)]);
      });
    fetch("/ci_cd/get_job_names")
      .then((response) => response.json())
      .then((data) => {
        setKnownJobNames(data.map((job: { job_name: string }) => job.job_name));
      });
  }, []);

  const loadData = async () => {
    const pipelineInformation = await fetchData(selectedJobName, "all", selectedProject, startDate);
    setPlottingData(pipelineInformation);
  };

  return (
    <>
      <NavBar />
      <div>
        <Box sx={{ m: 6 }}>
          <div>
            <InputLabel id="start-date-label">Start date</InputLabel>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
          </div>
          <SpacedDiv>
            <SearchableDropdown options={knownProjects} selectedOption={selectedProject} setSelectedOption={setSelectedProject} label="Project" />
          </SpacedDiv>
          <SpacedDiv>
            <SearchableDropdown options={knownJobNames} selectedOption={selectedJobName} setSelectedOption={setSelectedJobName} label="Job" />
          </SpacedDiv>
          <Button variant="contained" onClick={loadData}>
            Plot data
          </Button>
        </Box>
        <Grid2 container spacing={1}>
          <Grid2 xs={12} md={6}>
            <PipelineStatusOverview plottingData={plottingData} />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <FailureReasons
              plottingData={plottingData.filter((data) => data.status === "failed")}
              categoriesAccessor={(pipeline) => pipeline.gitlabFailureReason || "null"}
              title="Gitlab failure reasons"
            />
          </Grid2>
          <Grid2 md={12}>
            <TextField
              label="Time resolution (days)"
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTimeResolution(parseInt(event?.target.value) || 1)}
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <SuccessRateOverTime plottingData={plottingData} timeResolution={timeResolution} />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <JobsOverTime plottingData={plottingData} timeResolution={timeResolution} />
          </Grid2>
        </Grid2>
      </div>
    </>
  );
}
