import logo from "./logo.svg";
import { Divider } from "@material-ui/core";

import styled from "styled-components";
import { Box} from "@material-ui/core";
import { NavLink } from "react-router-dom";

const NavBarContainer = styled.div`
  width: 100vw;
  background-color: white;
  padding: 0 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const StyledBox = styled(Box)`
  width: 95vw;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
`;
const BoxDiv = styled(Box)`
  display: flex;
  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;
const NavLinks = styled.div`
  display: flex;
  @media (min-width: 768px) {
    width: 50vw;
    justify-content: center;
  }
`;

const LogoLink = styled.a`
  margin: auto 0;
  text-decoration: none;
  @media (min-width: 768px) {
    width: 20vw;
  }
`;

const Logo = styled.img`
  margin: auto;
  height: 25px;
`;
const Title = styled.p`
  font-family: "Cabin";
  font-size: 10px;
  color: #303544;
  width: 20px;
  padding: 0 50px 0 25px;
  margin: 0 30px;
  @media (min-width: 768px) {
    padding: 0 40px 0 0;
    margin: 0 20px;
  }
  @media (min-width: 1024px) {
    font-size: 12px;
    padding: 0 50px 0 25px;
    margin: 0 30px;
  }
`;

const NavItemLink = styled(NavLink)`
  color: black;
  text-decoration: none;
  margin: auto 10px;
  font-family: "Cabin" !important;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1024px) {
    font-size: 18px;
  }
  &.active {
    border-bottom: 4px solid #e44411;
    padding: 20px 0px 20px 0px;
  }
`;


export const NavBar = () => {
  const appendIfActive = (navLink: string, isActive: boolean) => {
    return [navLink, isActive ? "active" : null].filter(Boolean).join(" ");
  };

  return (
    <NavBarContainer>
      <StyledBox>
        <LogoLink>
          <BoxDiv>
            <Logo src={logo} />
            <Title>Hackathon 2024</Title>
            <Divider orientation="vertical" flexItem />
          </BoxDiv>
        </LogoLink>
        <NavLinks>
          <NavItemLink to="/" className={({ isActive }: { isActive: boolean }) => appendIfActive("nav-link", isActive)}>
            Overview
          </NavItemLink>
          <NavItemLink to="/error_inspection" className={({ isActive }: { isActive: boolean }) => appendIfActive("nav-link", isActive)}>
            Error inspection
          </NavItemLink>
          <NavItemLink to="/runtime" className={({ isActive }: { isActive: boolean }) => appendIfActive("nav-link", isActive)}>
            Runtime
          </NavItemLink>
        </NavLinks>
      </StyledBox>
    </NavBarContainer>
  );
};
