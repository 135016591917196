import { useMemo } from "react";
import Plot from "react-plotly.js";
import { computeFrequencyMap } from "../util";
import { PipelineInformation } from "../types";

export function PipelineStatusOverview({ plottingData }: { plottingData: PipelineInformation[] }) {
  const pipelineStatusCounts = useMemo(() => computeFrequencyMap(plottingData, (pipeline) => pipeline.status), [plottingData]);
  return (
    <>
      <Plot
        data={[
          {
            values: Array.from(pipelineStatusCounts.values()),
            labels: Array.from(pipelineStatusCounts.keys()),
            type: "pie",
          },
        ]}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
        layout={{
          title: "Overall pipeline status",
        }}
      />
    </>
  );
}
