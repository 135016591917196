import { TextField } from "@mui/material";
import Plot from "react-plotly.js";
import { JobsOverTimeProps, PipelineInformationWithStartTime, TimeData } from "../types";
import { useState, useMemo } from "react";
import { computeTimeDiscreteMap, pipelineInformationToWithStartTime } from "../DataProcessing";

const computeJobsOverTime = (plottingData: PipelineInformationWithStartTime[], timeResolution: number): TimeData[] => {
  //For now only overall, we could support by project
  const startTime = Math.min(...plottingData.map((data) => data.startTime.getTime()));
  const resolutionMs = timeResolution * 24 * 60 * 60 * 1000;
  const count = "count";
  const timeData = computeTimeDiscreteMap(plottingData, startTime, timeResolution, (data) => count);
  const plotData = Array.from(timeData).map(([timeBucket, statusMap]) => {
    return [new Date(startTime + timeBucket * resolutionMs), statusMap.get(count)] as const;
  });
  return [
    {
      x: plotData.map(([a, _]) => a),
      y: plotData.map(([_, b]) => b),
      type: "scatter",
    },
  ];
};

export function JobsOverTime({ plottingData, timeResolution }: JobsOverTimeProps) {
  const [internalTimeResolution, setInternalTimeResolution] = useState(1); //in days

  const jobsOverTime = useMemo(
    () => computeJobsOverTime(pipelineInformationToWithStartTime(plottingData), timeResolution || internalTimeResolution),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plottingData, timeResolution, internalTimeResolution]
  );
  return (
    <>
      {timeResolution !== undefined ? (
        <></>
      ) : (
        <div>
          <TextField
            label="Time resolution (days)"
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInternalTimeResolution(parseInt(event?.target.value) || 1)}
          />
        </div>
      )}
      <Plot
        data={jobsOverTime}
        config={{ responsive: true }}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%", minHeight: "400px" }}
        layout={{
          title: "Jobs over time",
          yaxis: { rangemode: "tozero" },
        }}
      />
    </>
  );
}
