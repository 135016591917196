import { FormControl, InputLabel, Select, ListSubheader, TextField, InputAdornment, MenuItem } from "@mui/material";
import { useState, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";

const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

interface SearchableDropdownProps {
  options: string[];
  selectedOption?: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  label: string;
}

export function SearchableDropdown({ options, selectedOption, setSelectedOption, label }: SearchableDropdownProps) {
  const [searchText, setSearchText] = useState("");
  const displayedRepos = useMemo(
    () => options.filter((option) => containsText(option, searchText)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText, options.join(",")]
  );
  return (
    <FormControl size={"small"} sx={{ minWidth: 120 }}>
      <InputLabel id="search-select-label">{label}</InputLabel>
      <Select
        // Disables auto focus on MenuItems and allows TextField to be in focus
        MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id="search-select"
        value={selectedOption}
        label="Options"
        onChange={(e) => setSelectedOption(e.target.value)}
        onClose={() => setSearchText("")}
        // This prevents rendering empty string in Select's value
        // if search text would exclude currently selected option.
        renderValue={() => selectedOption}
      >
        {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedRepos.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
