import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Runtime } from "./pages/Runtime";
import { ErrorInspection } from "./pages/ErrorInspection";
import { Overview } from "./pages/Overview";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/error_inspection" element={<ErrorInspection />} />
        <Route path="/runtime" element={<Runtime />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
