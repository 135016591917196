import { NavBar } from "../NavBarMenu";

export function Runtime() {
  return (
    <>
      <NavBar />
      <div>
        <h1>TODO here we only care about "success" state jobs</h1>
      </div>
    </>
  );
}
