import { TextField } from "@mui/material";
import Plot from "react-plotly.js";
import { PipelineInformation, PipelineInformationWithStartTime, TimeData } from "../types";
import { useState, useMemo } from "react";
import { computeTimeDiscreteMap, pipelineInformationToWithStartTime } from "../DataProcessing";

const computeSuccessRateOverTime = (plottingData: PipelineInformationWithStartTime[], timeResolution: number): TimeData[] => {
  //For now only overall, we could support by project
  const startTime = Math.min(...plottingData.map((data) => data.startTime.getTime()));
  const resolutionMs = timeResolution * 24 * 60 * 60 * 1000;
  const timeData = computeTimeDiscreteMap(plottingData, startTime, timeResolution, (data) => data.status);
  const normalizedData = Array.from(timeData).map(([timeBucket, statusMap]) => {
    const totalCount = Array.from(statusMap).reduce((total, [_, count]) => total + count, 0);
    return [new Date(startTime + timeBucket * resolutionMs), 1 - statusMap.get("failed") / totalCount] as const;
  });
  return [
    {
      x: normalizedData.map(([a, _]) => a),
      y: normalizedData.map(([_, b]) => b),
      type: "scatter",
    },
  ];
};

interface SuccessRateProps {
  plottingData: PipelineInformation[];
  timeResolution?: number;
}

export function SuccessRateOverTime({ plottingData, timeResolution }: SuccessRateProps) {
  const [internalTimeResolution, setInternalTimeResolution] = useState(1); //in days

  const successRateOverTime = useMemo(
    () => computeSuccessRateOverTime(pipelineInformationToWithStartTime(plottingData), timeResolution || internalTimeResolution),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plottingData, timeResolution, internalTimeResolution]
  );
  return (
    <>
      {timeResolution !== undefined ? (
        <></>
      ) : (
        <div>
          <TextField
            label="Time resolution (days)"
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInternalTimeResolution(parseInt(event?.target.value) || 1)}
          />
        </div>
      )}
      <Plot
        data={successRateOverTime}
        config={{ responsive: true }}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%", minHeight: "400px" }}
        layout={{
          title: "Success rate over time",
          yaxis: { range: [0, 1.05] },
        }}
      />
    </>
  );
}
