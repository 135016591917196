import { useMemo, useState } from "react";
import Plot from "react-plotly.js";
import { PipelineInformation, PipelineInformationWithStartTime, TimeData } from "../types";
import { DefaultMap, computeFrequencyMap } from "../util";
import { computeTimeDiscreteMap, pipelineInformationToWithStartTime } from "../DataProcessing";
import { TextField } from "@material-ui/core";

interface FailureReasonsProps {
  plottingData: PipelineInformation[];
  categoriesAccessor: (data: PipelineInformation) => string;
  title: string;
}
export function FailureReasons({ plottingData, categoriesAccessor, title }: FailureReasonsProps) {
  const failuresByGitlabReasons = useMemo(
    () =>
      computeFrequencyMap(
        plottingData.filter((pipeline) => pipeline.status === "failed"),
        categoriesAccessor
      ),
    [plottingData, categoriesAccessor]
  );
  return (
    <>
      <Plot
        data={[
          {
            values: Array.from(failuresByGitlabReasons.values()),
            labels: Array.from(failuresByGitlabReasons.keys()),
            type: "pie",
          },
        ]}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
        layout={{
          title,
        }}
      />
    </>
  );
}

interface FailureReasonsOverTimeProps extends FailureReasonsProps {
  timeResolution?: number;
}
const computeFailureReasonsOverTime = (
  plottingData: PipelineInformationWithStartTime[],
  timeResolution: number,
  categoriesAccessor: (data: PipelineInformation) => string
): TimeData[] => {
  const failedData = plottingData.filter((data) => data.status === "failed");
  //For now only overall, we could support by project
  const startTime = Math.min(...plottingData.map((data) => data.startTime.getTime()));
  const resolutionMs = timeResolution * 24 * 60 * 60 * 1000;
  const timeData = computeTimeDiscreteMap(failedData, startTime, timeResolution, categoriesAccessor);
  const normalizedDatas: DefaultMap<string, [Date, number][]> = new DefaultMap(() => []);
  Array.from(timeData).forEach(([timeBucket, statusMap]) => {
    const statusArr = Array.from(statusMap);
    const totalCount = statusArr.reduce((total, [_, count]) => total + count, 0);
    for (const [key, value] of statusArr) {
      normalizedDatas.get(key).push([new Date(startTime + timeBucket * resolutionMs), value / totalCount]);
    }
  });
  return Array.from(normalizedDatas).map(([name, normalizedData]) => {
    return {
      x: normalizedData.map(([a, _]) => a),
      y: normalizedData.map(([_, b]) => b),
      type: "scatter",
      name,
    };
  });
};

export function FailureReasonsOverTime({ plottingData, categoriesAccessor, title, timeResolution }: FailureReasonsOverTimeProps) {
  const [internalTimeResolution, setInternalTimeResolution] = useState(1); //in days
  const processedData = useMemo(
    () =>
      computeFailureReasonsOverTime(pipelineInformationToWithStartTime(plottingData), timeResolution || internalTimeResolution, categoriesAccessor),
    [plottingData, timeResolution, internalTimeResolution, categoriesAccessor]
  );
  return (
    <>
      {timeResolution !== undefined ? (
        <></>
      ) : (
        <div>
          <TextField
            label="Time resolution (days)"
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInternalTimeResolution(parseInt(event?.target.value) || 1)}
          />
        </div>
      )}
      <Plot
        data={processedData}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%", minHeight: "400px" }}
        layout={{
          title,
          yaxis: { rangemode: "tozero" },
        }}
      />
    </>
  );
}
