import { useEffect, useState } from "react";
import "../App.css";
import { InputLabel, Box, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavBar } from "../NavBarMenu";
import { SearchableDropdown } from "../SearchableDropdown";
import { FailureReasons, FailureReasonsOverTime } from "../plots/FailureReasons";
import { PipelineInformation } from "../types";
import { allSelection, fetchData } from "../DataProcessing";
import Grid2 from "@mui/material/Unstable_Grid2";

export function ErrorInspection() {
  const [selectedProject, setSelectedProject] = useState<string>(allSelection);
  const [knownProject, setKnownProject] = useState<string[]>([]);
  const [selectedJobName, setSelectedJobName] = useState<string>(allSelection);
  const [knownJobNames, setKnownJobNames] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [plottingData, setPlottingData] = useState<PipelineInformation[]>([]);

  useEffect(() => {
    fetch("/ci_cd/get_project_names")
      .then((response) => response.json())
      .then((data) => {
        setKnownProject(data.map((project: { project_name: string }) => project.project_name));
      });
    fetch("/ci_cd/get_job_names")
      .then((response) => response.json())
      .then((data) => {
        setKnownJobNames(data.map((job: { job_name: string }) => job.job_name));
      });
  }, []);
  const loadData = async () => {
    const pipelineInformation = await fetchData(selectedJobName, allSelection, selectedProject, startDate);
    //We only ever care about failed jobs here
    setPlottingData(pipelineInformation.filter((data) => data.status === "failed"));
  };

  return (
    <>
      <NavBar />
      <div>
        <Box sx={{ m: 6 }}>
          <div>
            <InputLabel id="start-date-label">Start date</InputLabel>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
          </div>
          <div>
            <SearchableDropdown options={knownProject} selectedOption={selectedProject} setSelectedOption={setSelectedProject} label="Project" />
          </div>
          <div>
            <SearchableDropdown options={knownJobNames} selectedOption={selectedJobName} setSelectedOption={setSelectedJobName} label="Job" />
          </div>
          <Button variant="contained" onClick={loadData}>
            Plot data
          </Button>
        </Box>
        <Grid2 container spacing={1}>
          <Grid2 xs={12} md={6}>
            <FailureReasons
              plottingData={plottingData}
              categoriesAccessor={(pipeline) => pipeline.gitlabFailureReason || "null"}
              title="Gitlab failure reasons"
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <FailureReasonsOverTime
              plottingData={plottingData}
              title="Gitlab failure reasons over time"
              categoriesAccessor={(pipeline) => pipeline.gitlabFailureReason || "null"}
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <FailureReasons
              plottingData={plottingData.filter((pipeline) => pipeline.gitlabFailureReason === "script_failure")}
              categoriesAccessor={(pipeline) => pipeline.errors?.failureType || "unclassified"}
              title="Script failure reasons"
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <FailureReasonsOverTime
              plottingData={plottingData.filter((pipeline) => pipeline.gitlabFailureReason === "script_failure")}
              categoriesAccessor={(pipeline) => pipeline.errors?.failureType || "unclassified"}
              title="Script failure reasons over time"
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <FailureReasons
              plottingData={plottingData.filter((pipeline) => pipeline.gitlabFailureReason === "runner_system_failure")}
              categoriesAccessor={(pipeline) => pipeline.errors?.failureType || "unclassified"}
              title="Runner system failure reasons"
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <FailureReasonsOverTime
              plottingData={plottingData.filter((pipeline) => pipeline.gitlabFailureReason === "runner_system_failure")}
              categoriesAccessor={(pipeline) => pipeline.errors?.failureType || "unclassified"}
              title="Runner system failure reasons over time"
            />
          </Grid2>
        </Grid2>
      </div>
    </>
  );
}
