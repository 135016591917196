import { PipelineError, PipelineInformation, PipelineInformationWithStartTime, RawData, RawError } from "./types";
import { DefaultMap } from "./util";

export const computeTimeDiscreteMap = (
  plottingData: PipelineInformation[],
  startTime: number,
  timeResolution: number,
  accessor: (data: PipelineInformation) => string
): DefaultMap<number, DefaultMap<string, number>> => {
  const resolutionMs = timeResolution * 24 * 60 * 60 * 1000;
  const timeData = new DefaultMap<number, DefaultMap<string, number>>(() => new DefaultMap(() => 0));
  let allTimeBuckets = new Set<number>();
  let allCategories = new Set<string>();
  for (const data of plottingData) {
    if (!data.startTime) continue;
    const timeBucket = Math.floor((data.startTime.getTime() - startTime) / resolutionMs);
    allTimeBuckets.add(timeBucket);
    const currentMap = timeData.get(timeBucket);
    const currentCategory = accessor(data);
    allCategories.add(currentCategory);
    currentMap.set(currentCategory, currentMap.get(currentCategory) + 1);
  }
  //Make sure we don't have data holes, but 0 instead
  for (const timeBucket of Array.from(allTimeBuckets)) {
    for (const category of Array.from(allCategories)) {
      const currentMap = timeData.get(timeBucket);
      //Because of default map this inserts 0 if it does not exist
      currentMap.set(category, currentMap.get(category));
    }
  }
  return timeData;
};

export const pipelineInformationToWithStartTime = (pipelineInformation: PipelineInformation[]): PipelineInformationWithStartTime[] => {
  return pipelineInformation
    .filter((data) => data.startTime)
    .map((data) => {
      return {
        ...data,
        startTime: data.startTime!, //We filtered them out above but typescript does not understand
      };
    });
};

export const allSelection = "all";

const mapErrors = (rawErrors?: RawError): PipelineError | undefined => {
  if (rawErrors) {
    return {
      message: rawErrors.message,
      packageName: rawErrors.package_name || "unknown",
      timestamp: new Date(rawErrors.timestamp),
      failureType: rawErrors.failure_type || "unknown",
    };
  }
};

export const fetchData = async (jobName: string, failureType: string, projectName: string, startDate: Date | null) => {
  const searchParams = new URLSearchParams();
  jobName !== allSelection && searchParams.set("job_name", jobName);
  failureType !== allSelection && searchParams.set("failure_type", failureType);
  projectName !== allSelection && searchParams.set("project_name", projectName);
  startDate && searchParams.set("start_date", startDate.toISOString());
  const rawData: RawData[] = await fetch("/ci_cd/get_data?" + searchParams).then((response) => response.json());
  return rawData.map((data) => {
    return {
      jobName: data.job_name,
      status: data.status,
      createdAt: data.created_at === null ? undefined : new Date(data.created_at),
      startTime: data.start_time === null ? undefined : new Date(data.start_time),
      finishTime: data.finish_time === null ? undefined : new Date(data.finish_time),
      gitlabFailureReason: data.gitlab_failure_reason,
      queuedDuration_s: data.queued_duration_s,
      allowFailure: data.allow_failure,
      errors: mapErrors(data.errors),
    };
  });
};
